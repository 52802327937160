<template>
	<v-app>
		<v-navigation-drawer
			clipped
			app
			dark
			color="blue-grey darken-4"
			width="250"
			v-if="drawer"
			v-model="drawer"
		>
			<v-treeview
				class="left-menu mt-2"
				dense
				:items="$store.getters['layout/menuItems']"
				activatable
				open-on-click
				item-key="id"
				:selectable="shortcutEditMode"
				selection-type="independent"
				:item-disabled="shortcutEditMode ? 'locked' : 'none'"
				v-model="shortcutItems"
				return-object
			>
				<template v-slot:label="{ item }">
					<span v-if="item.children"
						><v-icon
							small
							v-if="item.icon && item.locked != undefined"
							:color="item.color"
							class="mr-1"
							>{{ item.icon }}</v-icon
						>
						{{ item.name }}
					</span>
					<router-link :to="item.link" v-else>
						<div>
							<v-icon
								small
								v-if="item.icon && item.locked != undefined"
								:color="item.color"
								class="mr-1"
								>{{ item.icon }}</v-icon
							>
							{{ item.name }}
						</div>
					</router-link>
				</template>
			</v-treeview>
		</v-navigation-drawer>

		<v-app-bar
			:elevation="1"
			extended
			clipped-left
			fixed
			app
			class="custom-app-bar"
		>
			<v-container fluid class="py-0 px-1 light-blue darken-1">
				<v-row dense>
					<v-app-bar-nav-icon class="hidden-sm-only hidden-md-only hidden-lg-only" @click.stop="drawer = !drawer" />
					<template v-for="(menu, ind) in $store.getters['layout/menuItems']">
						<v-col cols="auto" class="pa-0 hidden-xs-only" :key="ind">
							<v-menu
								offset-y
								:close-on-content-click="false"
							>
								<template v-slot:activator="{ on }">
									<v-btn
										text
										dark
										tile
										small
										v-on="on"
										class="px-1 caption"
									>
										{{ menu.name }}
									</v-btn>
								</template>

								<v-treeview
									style="width: 250px; overflow: hidden"
									class="left-menu white"
									dense
									:items="menu.children"
									activatable
									item-key="name"
									open-on-click
								>
									<template v-slot:label="{ item }">
										<span v-if="item.children"
											><v-icon
												small
												v-if="item.icon && item.locked != undefined"
												:color="item.color"
												class="mr-1"
												>{{ item.icon }}</v-icon
											>
											{{ item.name }}</span
										>
										<router-link :to="item.link" v-else>
											<div>
												<v-icon
													small
													v-if="item.icon && item.locked != undefined"
													:color="item.color"
													class="mr-1"
													>{{ item.icon }}</v-icon
												>
												{{ item.name }}
											</div>
										</router-link>
									</template>
								</v-treeview>
							</v-menu>
						</v-col>

						<v-divider
							vertical
							inset
							class="light-blue lighten-3 my-1"
							:key="`dvd${ind}`"
						></v-divider>
					</template>

					<v-spacer></v-spacer>

					<v-col cols="auto" class="pa-0 green darken-3" v-if="userType == 'm'">
						<v-menu offset-y>
							<template v-slot:activator="{ on }">
								<v-btn
									text
									dark
									tile
									small
									v-on="on"
									class="px-1"
								>
									<v-icon small class="mr-2"
										>mdi-source-branch-sync</v-icon
									>
									<span class="caption">Branches</span>
								</v-btn>
							</template>

							<v-list nav dense class="top-menu">
								<v-list-item v-for="(branch, ind) in $store.getters['branch/branches']" :key="ind">
									<v-list-item-content>
										<v-list-item-title @click.stop="branchId = branch.id;dialog = true" class="text-truncate" style="width: 200px;">{{ branch.name }}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>

					<v-col cols="auto" class="pa-0 light-blue darken-3">
						<v-menu offset-y>
							<template v-slot:activator="{ on }">
								<v-btn
									text
									dark
									tile
									small
									v-on="on"
									class="px-1"
								>
									<v-icon small class="mr-1"
										>mdi-account-circle</v-icon
									>
									<span class="caption">{{ userName }}</span>
								</v-btn>
							</template>

							<v-list nav dense class="top-menu">
								<v-list-item>
									<v-list-item-icon class="mr-1 my-0">
										<v-icon small>mdi-account-edit</v-icon>
									</v-list-item-icon>

									<v-list-item-content class="py-0">
										<router-link to="/user-profile-setting">
											<v-list-item-title
												>Profile Settings</v-list-item-title
											>
										</router-link>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-icon class="mr-1 my-0">
										<v-icon small>mdi-key-change</v-icon>
									</v-list-item-icon>

									<v-list-item-content class="py-0">
										<v-list-item-title
											>Change Password</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-icon class="mr-1 my-0">
										<v-icon small>mdi-logout</v-icon>
									</v-list-item-icon>

									<v-list-item-content class="py-0">
										<v-list-item-title @click="logout">Logout</v-list-item-title
										>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
				</v-row>
			</v-container>

			<template v-slot:extension>
				<v-container fluid class="pa-0 grey lighten-5">
					<v-row dense class="pl-1 grey darken-3">
						<v-col
							cols="auto"
							class="pa-1 pr-0"
							v-for="(item, ind) in shortcutItems"
							:key="ind"
							style="position:relative"
						>
							<div v-if="shortcutEditMode" @click="shortcutRemove(ind)" class="shortcut-close">
								<v-icon>mdi-close</v-icon>
							</div>

							<v-card
								:elevation="1"
								:to="item.link"
								min-width="80px"
							>
								<v-card-text
									align="center"
									class="pa-0 px-1 pt-1"
								>
									<v-icon :color="item.color">{{
										item.icon
									}}</v-icon
									><br />
									<strong style="font-size: 12px">{{
										item.name
									}}</strong>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col cols="auto" class="pa-1 pr-0">
							<v-card
								@click="changeShortcutItems"
								width="78"
								height="50"
								color="grey lighten-2"
								:elevation="1"
							>
								<v-card-text align="center" class="pa-0 pt-3">
									<v-icon
										color="black"
										v-if="shortcutEditMode"
										>mdi-checkbox-marked-circle-outline</v-icon
									>
									<v-icon color="black" v-else
										>mdi-plus-circle-outline</v-icon
									>
								</v-card-text>
							</v-card>
						</v-col>

						<v-spacer></v-spacer>

						<v-col cols="auto" class="pa-0">
							<div id="clock" class="grey darken-3">
								<p class="date">
									{{
										time.toLocaleString("en-US", {
											day: "numeric",
											month: "numeric",
											year: "numeric",
											weekday: "short",
										})
									}}
								</p>
								<p class="time">
									{{
										time.toLocaleString("en-US", {
											hour: "numeric",
											minute: "numeric",
											second: "numeric",
											hour12: true,
										})
									}}
								</p>
							</div>
						</v-col>
					</v-row>

					<v-divider></v-divider>

					<v-row no-gutters>
						<v-col cols="auto" style="width: 250px">
							<v-card
								dark
								flat
								tile
								color="light-blue darken-3 d-flex align-center justify-center"
								height="32"
							>
								<span class="subtitle-2 text-truncate" style="width:200px">{{ branchName }}</span>
							</v-card>
						</v-col>

						<v-col cols="auto" style="width: calc(100% - 250px)">
							<v-card
								tile
								dark
								color="grey lighten-4"
								:elevation="1"
								height="30px"
							>
								<v-card-text class="pa-0">
									<v-tabs
										class="open-pages"
										active-class="active-tab"
										light
										background-color="grey lighten-4"
										show-arrows
										height="32px"
									>
										<v-tabs-slider
											color="grey lighten-4"
										></v-tabs-slider>

										<v-tab
											class="pa-0"
											v-for="(page, ind) in openPages"
											:key="ind"
										>
											<v-chip
												:close="
													page.name == 'Dashboard'
														? false
														: true
												"
												label
												dark
												:color="
													page.active
														? page.color
														: 'grey lighten-2 black--text'
												"
												class="my-1 ml-1"
												small
												:to="page.link"
												@click:close="closeTab(ind)"
											>
												{{ camelPad(page.name) }}
											</v-chip>
										</v-tab>
									</v-tabs>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</v-app-bar>

		<v-main>
			<v-container fluid class="pa-0">
				<v-row dense>
					<v-col class="py-0">
						<router-view />
					</v-col>
				</v-row>
			</v-container>
		</v-main>

		<v-footer app dark color="#424242" class="pa-0" height="26px">
			<v-container fluid class="pa-0">
				<v-row>
					<v-col cols="10" class="py-0">
						<marquee direction="left" scrolldelay="500" onmouseover="this.stop();" onmouseout="this.start();">
							<span class="caption" v-for="(notice, i) in $store.getters['notice/notices']" :key="i"> 
								Date:{{ notice.date }} &nbsp; Approved By: {{ notice.employee.name }} &nbsp; Notice: {{ notice.description }} &nbsp;&nbsp;
							</span
							>
						</marquee>
					</v-col>

					<v-col cols="2" class="py-0 pr-0 success">
						<v-icon small class="mr-1">mdi-account-circle</v-icon>
						<span class="caption">{{ userFullName }}</span>
					</v-col>
				</v-row>
			</v-container>
		</v-footer>

		<!-- dialog start-->
		<v-dialog
			v-model="dialog"
			max-width="390"
		>
			<v-card>
				<v-card-title class="text-h5">
					Are you sure to access branch?
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text class="py-8 text-center">
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						dark
						@click="changeBranch"
					>
						Agree
					</v-btn>

					<v-btn
						class="ml-10"
						color="red"
						dark
						@click="dialog = false"
					>
						Disagree
					</v-btn>
				</v-card-text>
			</v-card>
		</v-dialog>
		<!-- dialog end-->

		<snackbar></snackbar>
	</v-app>
</template>

<script>
import utilities from './../mixins/utilities'
import snackbar from '../components/snackbar.component';
export default {
	mixins: [utilities],
	
	components: {
		snackbar,
	},

	data: () => ({
		userFullName: '',
		userName: '',
		branchName: '',
		userType: '',
		userId: '',
		time: "",
		shortcutEditMode: false,
		shortcutItems: [],
		openPages: [
			{ name: "Dashboard", link: "/", active: false, color: "brown" },
		],
		dialog: false,
		branchId: null,
		drawer: true,
	}),

	watch: {
		$route(to, from) {
			this.checkPermission(to.path)
			this.$store.getters['layout/menuItems'].forEach((menu) => (menu.show = false));
			this.openPages.forEach((page) => (page.active = false));

			let currentPage = this.openPages.find(
				(page) => page.name == to.name
			);
			if (currentPage == undefined) {
				this.openPages.push({
					name: to.name,
					link: to.path,
					active: true,
					color: to.meta.color,
				});
			} else {
				currentPage.active = true;
			}
		},
	},

	async mounted() {
        let jwtToken =  localStorage.getItem('jwtToken')
		if(jwtToken == null || jwtToken == '')  this.$router.push('/login')
		
    },

	async created() {
		let jwtToken =  localStorage.getItem('jwtToken')
		if(jwtToken != null || jwtToken != '') {
			await this.$store.dispatch('user/getShortcuts', {userId: this.userId})

			if (await this.$store.getters['user/shortcuts'] != null) {
				this.shortcutItems = JSON.parse(this.$store.getters['user/shortcuts']);
			}

			await this.$store.dispatch('branch/getBranches')
		}

		await this.$store.dispatch('notice/getBusinessMonitorNotice')

		let userData = JSON.parse(localStorage.getItem('userData'))
		this.userFullName = userData.name;
		this.userName = userData.username;
		this.branchName = userData.branchName;
		this.userType = userData.userType;
		this.userId = userData.userId;

		if (this.userType == 'm') {
            this.$store.getters['layout/menuItems']
        } else {
            let permissions = this.getPermissions()
			if(permissions.length) {
				let menuItems = this.makeNestedMenu(this.$store.getters['layout/menuItems'], permissions)
				this.$store.commit('layout/setMentItem', menuItems)
			}
        }

		this.checkPermission(this.$route.path)
		setInterval(() => (this.time = new Date()), 1000);
	},

	methods: {
		async changeBranch() {
			if(this.branchId == null) return;
			let isSuccess = await this.$store.dispatch('user/branchAccess', {branchId: this.branchId})
			if(isSuccess) {
				this.dialog = false;
				if(this.$route.name != 'Dashboard') {
					this.$router.push({ name: 'Dashboard' })
				} 
				let userData = JSON.parse(localStorage.getItem('userData'))
				this.userFullName = userData.name;
				this.userName = userData.username;
				this.branchName = userData.branchName;
			}
		},

		closeTab(ind) {
			this.openPages.splice(ind, 1);
			this.$router.push(this.openPages[ind - 1].link);
		},
		
		async changeShortcutItems() {
			this.shortcutEditMode = !this.shortcutEditMode;
			if (!this.shortcutEditMode) {
				let shortcutItems = JSON.stringify(this.shortcutItems);

				let data = {
					shortcutItems: shortcutItems, 
					id: this.userId
				}

				await this.$store.dispatch('user/userShortcut', data)
			}
		},

		shortcutRemove(index) {
			this.shortcutItems.splice(index, 1);
		},

		logout() {
			this.$store.dispatch('authorized/logout')
			let logoutRoute = '';
            let user = this.$store.getters['authorized/user'];

            if(user.userType == 'teacher') logoutRoute = '/teacher-login';
            else if(user.userType == 'student') logoutRoute = '/student-login';
            else logoutRoute = '/login'
    
            this.$router.push(logoutRoute);
		},

		getPermissions() {
			let user = JSON.parse(localStorage.getItem('userData'))
            let permissions = user.permissions != null ? user.permissions : []
			return permissions;
		},

		checkPermission(toRouteName) {
			if (this.userType == 'm') return
            if (this.$route.name == 'user-profile-setting') return
            if (this.$route.name == 'SaleInvoice') return
            if (this.$route.name == 'PurchaseInvoice') return
            if (this.$route.name == 'PurchaseReturnInvoice') return
            if (this.$route.name == 'CollectionInvoce') return
            if (this.$route.name == 'TCInvoice') return
            if (this.$route.name == 'SalaryGenerateInvoice') return
            if (this.$route.name == 'BonusInvoice') return

            if (toRouteName) {
				let permissions = this.getPermissions()
                if (permissions.length) {
					let hasPermission = permissions.indexOf(toRouteName)
					let perseData = JSON.parse(permissions)
					let studentMenu = perseData.filter(i => i.link == '/student')
					let saleMenu = perseData.filter(i => i.link == '/sale')
					let purchaseMenu = perseData.filter(i => i.link == '/purchase')
					let resultMenu = perseData.filter(i => i.link == '/exam-result')
					let employeeMenu = perseData.filter(i => i.link == '/employee')
					let salaryGenerateMenu = perseData.filter(i => i.link == '/salary-generate')
					let bonusMenu = perseData.filter(i => i.link == '/bonus')
					
					if (studentMenu.length > 0 && this.userType == 'a' && this.$route.name == 'StudentEntry') return
					if (saleMenu.length > 0 && this.userType == 'a' && this.$route.name == 'SaleEntry') return
					if (purchaseMenu.length > 0 && this.userType == 'a' && this.$route.name == 'PurchaseEntry') return
					if (resultMenu.length > 0 && this.userType == 'a' && this.$route.name == 'ExamResult') return
					if (employeeMenu.length > 0 && this.userType == 'a' && this.$route.name == 'Employee') return
					if (salaryGenerateMenu.length > 0 && this.userType == 'a' && this.$route.name == 'SalaryGenerate') return
					if (bonusMenu.length > 0 && this.userType == 'a' && this.$route.name == 'Bonus') return
                    if (hasPermission == -1 && this.$route.path != '/')
                    this.$router.push('/')
                }
            }
		}
	},
};
</script>

<style lang="scss">
.left-menu {
	.v-treeview-node__root {
		min-height: 28px !important;

		.v-treeview-node__content {
			margin-left: 0px !important;

			.v-treeview-node__prepend {
				margin-right: 0px !important;
			}

			.v-treeview-node__label {
				font-size: 12px !important;
				font-weight: normal !important;
			}
		}
	}
}

.v-btn:not(.v-btn--round).v-size--small {
    min-height: 38px !important;
    min-width: 80px;
    padding: 10px;
}

#clock {
	// background: #0f3854;
	background: radial-gradient(ellipse at center, #0a2e38 40%, #000000 80%);
	background-size: 100%;
	font-family: "Share Tech Mono", monospace;
	color: #ffffff;
	text-align: center;
	color: #daf6ff;
	text-shadow: 0 0 20px rgba(10, 175, 230, 1), 0 0 20px rgba(10, 175, 230, 0);
	padding: 5px 15px;
	.time {
		letter-spacing: 0.05em;
		font-size: 18px;
		padding: 0;
		margin: 0;
	}
	.date {
		padding: 0;
		margin: 0;
		letter-spacing: 0.1em;
		font-size: 14px;
	}
}

.open-pages {
	.v-tab {
		text-transform: none !important;
		font-weight: normal !important;
		letter-spacing: normal !important;
		min-width: 50px!important;
	}

	.v-slide-group__next,
	.v-slide-group__prev {
		flex: 0 1 20px !important;
		min-width: 20px !important;
	}
}

.active-tab {
	color: white !important;
}

.shortcut-close {
	position: absolute;
	right: -5px;
	top: 0px;
	background: #FF1E0E;
	border-radius: 50%;
	z-index: 99999;
	cursor: pointer;
	height: 17px;
	width: 17px;
	text-align: center;

	i {
		color: #ffffff !important;
		font-size: 13px !important;
		top: -4px;
	}

	&:hover {
		background: #424242;
		transition: .3s;
	}
}

.v-list-item__title {
	cursor: pointer;
}

@media screen and (min-device-width: 310px) and (max-device-width: 768px) { 
	.v-application .light-blue.lighten-3 {
		background: transparent !important;
		border: transparent !important;
	}
}

</style>
