export default {
    namespaced: true,

    state: () => {
        return {
            users: [],
            loading: false,
            shortcuts: []
        }
    },

    getters: {
        users(state) {
            return state.users.map((item, sl) => {
                item.sl = sl + 1;
                item.type = item.user_type == 'm' ? 'Supper Admin' : item.user_type == 'a' ? 'Admin' : 'User'
                return item;
            });
        },

        shortcuts(state) {
            return state.shortcuts
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading;
        },

        setUser(state, user) {
            this.commit('authorized/setUser', user);
        },

        populateUsers(state, user) {
            state.users = user
        },

        setShortcut(state, shortcut) {
            state.shortcuts = shortcut
        }
    },

    actions: {
        async getUsers(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-users`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('populateUsers', res.data.users);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveUser(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-user';
            } else {
                url = 'add-user';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getUsers');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteUser(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-user/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getUsers');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async login(context, user) {
            let isSuccess = false;
            
            let auth = await axios.post(`${this.state.host}/login`, user)
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.commit('setUser', { user: res.data.user, jwt: res.data.jwt });

                localStorage.setItem('jwtToken', res.data.jwt)
                localStorage.setItem('userData', JSON.stringify(res.data.user))
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async branchAccess(context, payload) {
            let isSuccess = false;

            await axios.post(`${this.state.host}/branch-access`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.commit('setUser', { user: res.data.user, jwt: res.data.jwt });
                localStorage.setItem('jwtToken', res.data.jwt)
                localStorage.setItem('userData', JSON.stringify(res.data.user))
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async userShortcut(context, payload) {
            let isSuccess = false;

            await axios.post(`${this.state.host}/user-shortcut`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getShortcuts');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getShortcuts(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-shortcuts`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setShortcut', res.data.user.shortcuts);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveUserPermission(context, payload) {
            let isSuccess = false;

            await axios.post(`${this.state.host}/add-user-permission`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        }
    }
}
