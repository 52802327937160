export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            employees: [],
            empId: '',
            attendances: [],
            bioId: '',
            salarySheets: [],
            payments: [],
            dues: [],
            statements: []
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        employees(state) {
            return state.employees.map((item, sl) => {
                item.sl = sl + 1;
                item.display_name = `${item.emp_id} - ${item.name}`
                return item;
            });
        },

        empId(state) {
            return state.empId;
        },

        bioId(state) {
            return state.bioId;
        },

        attendances(state) {
            return state.attendances.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },

        salarySheets(state) {
            return state.salarySheets;
        },

        payments(state) {
            return state.payments.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },

        dues(state) {
            return state.dues;
        },

        statements(state) {
            return state.statements;
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setEmployee(state, employees) {
            state.employees = employees
        },

        GenerateNewEmployeeId(state, id) {
            state.empId = id;
        },

        setBiometricId(state, bio) {
            state.bioId = bio
        },

        setAttendance(state, attendances) {
            state.attendances = attendances
        },

        setSalarySheet(state, sheets) {
            state.salarySheets = sheets
        },

        setPayment(state, payment) {
            state.payments = payment
        },

        setDue(state, due) {
            state.dues = due
        },

        setSalaryStatement(state, statements) {
            state.statements = statements
        }
    },

    actions: {
        async getGeneratedEmployeeId(context) {
        
            await axios.get(`${this.state.host}/generate-employee-id`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('GenerateNewEmployeeId', res.data);
            })
            .catch(error => this.dispatch('snackbar/error', error))

        },

        async generateBiometricCode(context) {
            await axios.get(`${this.state.host}/get-employee-biometric-id`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setBiometricId', res.data.biometric);
            })
            .catch(error => this.dispatch('snackbar/error', error))

        },

        async getEmployees(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-employees`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setEmployee', res.data.employees);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveEmployee(context, {employee, educations, previousEmployments, image, signatureImage, nomineeImage}){
            let isSuccess = false;

            let url = '';

            if(employee.id != null) {
                url = 'update-employee';
            } else {
                url = 'add-employee';
                delete employee.id;
            }

            let fd = new FormData();
            fd.append('employee', JSON.stringify(employee));
            fd.append('educations', JSON.stringify(educations));
            fd.append('previousEmployments', JSON.stringify(previousEmployments));

            if(image != null && image != undefined) {
                fd.append('image', image);
            }

            if(signatureImage != null && signatureImage != undefined) {
                fd.append('signatureImage', signatureImage);
            }

            if(nomineeImage != null && nomineeImage != undefined) {
                fd.append('nomineeImage', nomineeImage);
            }

            await axios.post(`${this.state.host}/${url}`, fd, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getEmployees');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteEmployee(context, {employee, password}) {
            let isSuccess = false;
            
            let fd = new FormData();
            fd.append('employee', JSON.stringify(employee));
            fd.append('password', password);

            await axios.post(`${this.state.host}/delete-employee`, fd, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getEmployees');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getEmployeeAttendanceRecord(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-employee-attendances`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setAttendance', res.data.attendances);
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            context.commit('setLoading', false);
        },

        async salaryGenerate(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.salary.id != null) {
                url = 'update-salary-generate'
            } else {
                url = 'add-salary-generate'
                delete payload.salary.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })
            
            return isSuccess;
        },

        async getSalarySheets(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-salary-sheets`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setSalarySheet', res.data.sheets);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async getEmployeePayment(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-employee-payments`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setPayment', res.data.payments);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveEmployeePayment(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-employee-payment'
            } else {
                url = 'add-employee-payment'
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })
            
            return isSuccess;
        },

        async deleteEmployeePayment(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-employee-payment/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getEmployeeDue(context, payload) {
            await axios.post(`${this.state.host}/get-employee-due`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setDue', res.data.dues);
            })
            .catch(error => this.dispatch('snackbar/error', error))
        },

        async getSalaryForEmployees(context, payload) {
            let employee = await axios.post(`${this.state.host}/get-salary-for-employees`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.employees;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return employee;
        },

        async getSalaryStatement(context, payload) {
            await axios.post(`${this.state.host}/get-salary-statement`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setSalaryStatement', res.data.statement);
            })
            .catch(error => this.dispatch('snackbar/error', error))
        },

        async deleteSalaryGenerate(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-salary-generate/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getEmployeeAttendanceGraph(context) {
            let graph = await axios.get(`${this.state.host}/get-attendance-graph`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.attendances;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return graph;
        }
    }
}