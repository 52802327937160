export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            results: [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        results(state) {
            return state.results.map((item, sl) => {
                item.sl = sl + 1;
                item.employee.display_name = `${item.employee.emp_id} - ${item.employee.name}`
                return item;
            });
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setExamResult(state, results) {
            state.results = results
        },
    },

    actions: {
        async getExamResults(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-exam-results`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setExamResult', res.data.results);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveExamResult(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.result.id != null) {
                url = 'update-exam-result';
            } else {
                url = 'add-exam-result';
                delete payload.result.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                // context.dispatch('getExamResults');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteExamResult(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-exam/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getExamResults');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getMarkSheets(context, payload) {
            let marksheet = await axios.post(`${this.state.host}/get-mark-sheets`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.results;
                // context.commit('setExamResult', res.data.results);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return marksheet;
        },

        async deleteResult(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-exam-result/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getResultPublish(context, payload) {
            let result = await axios.post(`${this.state.host}/get-result-publists`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.results;
                // context.commit('setExamResult', res.data.results);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return result;
        }
    }
}
